import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { ZoomMtg } from "@zoomus/websdk";
import { KJUR } from "jsrsasign";
// var Sample = require("./../public/tools/nav.js");
const rootElement: HTMLElement = document.getElementById(
  "ZoomEmbeddedApp"
) as HTMLElement;

declare global {
  interface Window {
    testTool: any;
    nav: any;
  }
}
console.log("rootElement-----------------", rootElement);

const testTool = window.testTool;
const nav = window.nav;


// get meeting args from url
const tmpArgs = testTool.parseQuery();
console.log("test tmpArgs------------", tmpArgs);
const meetingConfig = {
  sdkKey: tmpArgs.sdkKey,
  meetingNumber: tmpArgs.mn,
  userName: (function () {
    if (tmpArgs.name) {
      try {
        return testTool.b64DecodeUnicode(tmpArgs.name);
      } catch (e) {
        return tmpArgs.name;
      }
    }
  })(),
  password: tmpArgs.pwd,
  leaveUrl: "/index.html",
  role: parseInt(tmpArgs.role, 10),
  userEmail: (function () {
    try {
      return testTool.b64DecodeUnicode(tmpArgs.email);
    } catch (e) {
      return tmpArgs.email;
    }
  })(),
  lang: tmpArgs.lang,
  signature: tmpArgs.signature || "",
  webEndpoint: "zoom.us"
};
console.log("meetingConfig index ----------- ", meetingConfig);

if (!meetingConfig.signature) {
  websdkready();
  // window.location.href = "./nav.html";
}
else {
  const zmClient = ZoomMtgEmbedded.createClient();
  console.log("zmClient ----------- ", zmClient);
  const tmpPort = window.location.port === "" ? "" : ":" + window.location.port;
  const avLibUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    tmpPort +
    "/lib";
  zmClient
    .init({
      debug: true,
      zoomAppRoot: rootElement,
      assetPath: avLibUrl,
      language: meetingConfig.lang
    })
    .then((e: any) => {
      console.log("init success", e);
    })
    .catch((e: any) => {
      console.log("init error", e);
    });

  // WebSDK Embedded join
  zmClient
    .join({
      sdkKey: meetingConfig.sdkKey,
      signature: meetingConfig.signature,
      meetingNumber: meetingConfig.meetingNumber,
      userName: meetingConfig.userName,
      password: meetingConfig.password,
      userEmail: meetingConfig.userEmail
    })
    .then((e: any) => {
      console.log("join success", e);
    })
    .catch((e: any) => {
      console.log("join error", e);
    });

}
function websdkready() {
  interface TestTool {
    b64EncodeUnicode: (str: string) => string;
    b64DecodeUnicode: (str: string) => string;
    isMobileDevice: () => boolean;
    createZoomNode: (id: string, url: string) => void;
    getCurrentDomain: () => string;
    parseQuery: () => { [key: string]: string };
    serialize: (obj: { [key: string]: any }) => string;
    detectIE: () => string | boolean;
    getBrowserInfo: () => string | string[] | boolean;
    getRandomInt: (max: number) => number;
    extractHostname: (url: string) => string;
    getDomainName: (hostName: string) => string;
    setCookie: (cname: string, cvalue: string) => void;
    getCookie: (cname: string) => string;
    deleteAllCookies: () => void;
  }
  const testTool: TestTool = {
    b64EncodeUnicode: function (str: string) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          (match, p1) => String.fromCharCode(parseInt(p1, 16))
        )
      );
    },
    b64DecodeUnicode: function (str: string) {
      return decodeURIComponent(
        atob(str)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
    },
    isMobileDevice: function () {
      return !!(
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      );
    },
    createZoomNode: function (id: string, url: string) {
      const zoomIframe = document.createElement("iframe");
      zoomIframe.id = id;
      zoomIframe.setAttribute(
        'sandbox',
        'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
      );
      zoomIframe.allow = 'microphone; camera; fullscreen;';
      zoomIframe.src = url;
      zoomIframe.setAttribute('style', ''); // Use setAttribute to set style
      if (typeof document.body.append === 'function') {
        document.body.append(zoomIframe);
      } else {
        document.body.appendChild(zoomIframe);
      }
    },
    getCurrentDomain: function () {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
      );
    },
    parseQuery: function (): Record<string, string> {
      const href = window.location.href;
      const queryString = href.substr(href.indexOf("?"));
      const query: Record<string, string> = {};
      const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");

      for (let i = 0; i < pairs.length; i += 1) {
        const pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
      }

      return query;
    },
    serialize: function (obj: Record<string, any>): string {
      const keyOrderArr: string[] = [
        "name",
        "mn",
        "email",
        "pwd",
        "role",
        "lang",
        "signature",
        "china",
      ];

      function intersect(...args: string[][]): string[] {
        const result: string[] = [];
        const obj: Record<string, number> = {};
        for (let i = 0; i < args.length; i++) {
          for (let j = 0; j < args[i].length; j++) {
            const str = args[i][j];
            if (!obj[str]) {
              obj[str] = 1;
            } else {
              obj[str]++;
              if (obj[str] === args.length) {
                result.push(str);
              }
            }
          }
        }
        return result;
      }

      if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, "includes", {
          enumerable: false,
          value: function (searchElement: string) {
            const newArr = this.filter((el: string) => {
              return el === searchElement;
            });
            return newArr.length > 0;
          },
        });
      }

      const tmpInterArr = intersect(keyOrderArr, Object.keys(obj));
      const sortedObj: [string, any][] = [];
      keyOrderArr.forEach((key) => {
        if (tmpInterArr.includes(key)) {
          sortedObj.push([key, obj[key]]);
        }
      });

      Object.keys(obj)
        .sort()
        .forEach((key) => {
          if (!tmpInterArr.includes(key)) {
            sortedObj.push([key, obj[key]]);
          }
        });

      const tmpSortResult = sortedObj
        .map(([key, value]) => {
          if (typeof value !== "undefined") {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }
          return "";
        })
        .filter((str) => str !== "")
        .join("&");

      return tmpSortResult;
    },
    detectIE: function (): string | boolean {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf("MSIE ");
      if (msie > 0) {
        return "IE" + parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
      }

      const trident = ua.indexOf("Trident/");
      if (trident > 0) {
        const rv = ua.indexOf("rv:");
        return "IE" + parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
      }

      const edge = ua.indexOf("Edge/");
      if (edge > 0) {
        return "Edge" + parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
      }
      return false;
    },
    getBrowserInfo: function (): string | RegExpMatchArray {
      const agent = navigator.userAgent.toLowerCase();
      const regStr_ff = /firefox\/[\d.]+/gi;
      const regStr_chrome = /chrome\/[\d.]+/gi;
      const regStrChrome2 = /ipad; cpu os (\d+_\d+)/gi;
      const regStr_saf = /version\/[\d.]+/gi;
      const regStr_saf2 = /safari\/[\d.]+/gi;

      const regStr_edg = /edg\/[\d.]+/gi;

      // firefox
      if (agent.indexOf("firefox") > 0) {
        return agent.match(regStr_ff)!;
      }

      // Safari
      if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
        let tmpInfo: string | RegExpMatchArray = "safari/unknown";
        let tmpInfo2: RegExpMatchArray | null;
        tmpInfo = agent.match(regStr_saf)!;
        tmpInfo2 = agent.match(regStr_saf2);
        if (tmpInfo) {
          tmpInfo = tmpInfo.toString().replace("version", "safari");
        }
        if (tmpInfo2) {
          tmpInfo = tmpInfo2.toString().replace("version", "safari");
        }
        return tmpInfo;
      }

      // IE / Edge
      const tmpIsIE: any = detectIE();
      if (tmpIsIE) {
        return tmpIsIE;
      }

      // Chrome
      if (agent.indexOf("chrome") > 0) {
        return agent.match(regStr_chrome)!;
      }

      return "other";
    },
    getRandomInt: function (max: number) {
      // Random integer logic here
      return Math.floor(Math.random() * Math.floor(max)); // Replace with actual implementation
    },
    extractHostname: function (url: string): string {
      let hostname: string;
      if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
      } else {
        hostname = url.split("/")[0];
      }
      hostname = hostname.split(":")[0];
      hostname = hostname.split("?")[0];
      return hostname;
    },
    getDomainName: function (hostName: string): string {
      return hostName.substring(
        hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
      );
    },
    setCookie: function (cname: string, cvalue: string): void {
      const exdays = 1;
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie: function (cname: string): string {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    deleteAllCookies: function (): void {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
  };

  var CLIENT_ID = "7KtGg_UVTVu1pg8KEy4Gw";
  var CLIENT_SECRET = "OYMP3gFDKSy3ej2isI3tyLIW0eS6LtPk";
  var meeting_number_var = "84990840460";
  var meeting_pwd_var = "m8R99u";

  function getMeetingConfig(): { [key: string]: any } {
    return {
      mn: parseInt(meeting_number_var),
      name: testTool.b64EncodeUnicode("harsh"),
      pwd: meeting_pwd_var,
      role: 0,
      email: "",
      lang: "en-US",
      signature: "",
    };
  }

  var meetingConfig = getMeetingConfig();
  console.log("meetingConfig.name-------", meetingConfig.name);
  if (!meetingConfig.mn || !meetingConfig.name) {
    alert("Meeting number or username is empty");
    return false;
  }
  meeting_setting();

  function meeting_setting() {
    // e?.preventDefault();
    const meetingConfig = getMeetingConfig();
    console.log("meetingConfig-------", meetingConfig);
    if (!meetingConfig.mn || !meetingConfig.name) {
      alert("Meeting number or username is empty");
      return false;
    }
    let joinUrl = "";
    // Assuming generateSDKSignature is imported and defined somewhere
    // const signature =ZoomMtg.generateSDKSignature({
    //   meetingNumber: meetingConfig.mn,
    //   sdkKey: CLIENT_ID,
    //   sdkSecret: CLIENT_SECRET,
    //   role: meetingConfig.role
    // });
  
 

    function generateSignature(key: string | undefined, secret: string | jsrsasign.RSAKey | KJUR.crypto.ECDSA | { b64: string; } | { hex: string; } | { utf8: string; } | { rstr: string; } | { b64u: string; } | undefined, meetingNumber: any, role: number) {

      const iat = Math.round(new Date().getTime() / 1000) - 30
      const exp = iat + 60 * 60 * 2
      const oHeader = { alg: 'HS256', typ: 'JWT' }

      const oPayload = {
        sdkKey: key,
        appKey: key,
        mn: meetingNumber,
        role: role,
        iat: iat,
        exp: exp,
        tokenExp: exp
      }

      const sHeader = JSON.stringify(oHeader)
      const sPayload = JSON.stringify(oPayload)
      const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, secret)
      return sdkJWT
    }
    const signature=generateSignature(CLIENT_ID, CLIENT_SECRET, meeting_number_var, 0)
    console.log("signature-",signature);
    if (signature) {
      meetingConfig.signature =signature;
      meetingConfig.sdkKey = CLIENT_ID;
      joinUrl = "/index.html?" + testTool.serialize(meetingConfig);
      window.close();
      console.log(joinUrl);
      openMeetingInNewTab(joinUrl);
     }
  }
}
function openMeetingInNewTab(joinUrl: any) {
 window.open(joinUrl, "_blank");
//  meet_Tab?.focus();
};

function detectIE() {
  throw new Error("Function not implemented.");
}








